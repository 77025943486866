<template>
    <div class="notfound">
        <img src="../assets/404.png" alt="404(Not Found)">
        <h1>404</h1>
        <p>The page you are looking for is not available</p>
    </div>
</template>

<script>
  
  export default {
    name: 'NotFound',
    components: {

    },
    data: () => ({
    }),

    methods: {

    },
  }
</script>
<style scoped>
.notfound {
	text-align: center;
	margin-top: 7%;
    color: white;
}
</style>